import {ReactElement, useEffect} from 'react';
import {connect} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {Dispatch} from 'redux';

import {
  PaymentMode,
  QUERY_PARAM_PAYMENT_BUYER_EMAIL,
  QUERY_PARAM_PAYMENT_MODE,
  QUERY_PARAM_PAYMENT_NFT_ID,
  QUERY_PARAM_PAYMENT_ORDER_ID,
  QUERY_PARAM_PAYMENT_QUANTITY,
} from '../../../api/payment/types';
import {PurchaseWindowReplaceResultCommand} from '../../../redux/modules/purchase/actions';

type Props = {
  children: ReactElement | ReactElement[];
  showResultModal: (nftId: string, orderId: string, quantity: number, email: string) => void;
};

const PaymentHandler = ({children, showResultModal}: Props) => {
  const {search} = useLocation();
  const history = useHistory();

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const paymentMode = searchParams.get(QUERY_PARAM_PAYMENT_MODE);
    const nftId = searchParams.get(QUERY_PARAM_PAYMENT_NFT_ID);
    const orderId = searchParams.get(QUERY_PARAM_PAYMENT_ORDER_ID);
    const buyerEmail = searchParams.get(QUERY_PARAM_PAYMENT_BUYER_EMAIL);
    const quantity = searchParams.get(QUERY_PARAM_PAYMENT_QUANTITY);
    if (paymentMode === PaymentMode.WINDOW_REPLACE && nftId && orderId && buyerEmail && quantity) {
      const url = new URL(window.location.href);
      url.searchParams.delete(QUERY_PARAM_PAYMENT_MODE);
      url.searchParams.delete(QUERY_PARAM_PAYMENT_NFT_ID);
      url.searchParams.delete(QUERY_PARAM_PAYMENT_ORDER_ID);
      url.searchParams.delete(QUERY_PARAM_PAYMENT_BUYER_EMAIL);
      url.searchParams.delete(QUERY_PARAM_PAYMENT_QUANTITY);

      showResultModal(nftId, orderId, parseInt(quantity), buyerEmail);
      history.push(url.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showResultModal: (nftId: string, orderId: string, quantity: number, email: string) =>
    dispatch(PurchaseWindowReplaceResultCommand.create(nftId, orderId, quantity, email)),
});

export const PaymentHandlerConnected = connect(mapStateToProps, mapDispatchToProps)(PaymentHandler);
