import {cx} from '@linaria/core';
import {Box, makeStyles, Theme, Typography} from '@material-ui/core';
import {memo, useCallback, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import colors from '../../../../styles/colors';
import {useIsOverflow} from '../../../hooks/useIsOverflow';

const useStyles = makeStyles<Theme, {rowsCount: number; clientHeight?: number; scrollHeight?: number}>((theme) => ({
  text: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: colors.secondaryDark,
    letterSpacing: '0.75',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: ({rowsCount}) => rowsCount,
    height: ({clientHeight}) => `${clientHeight}px`,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'height 100ms ease-out',
  },
  textShowed: {
    display: 'block',
    WebkitLineClamp: 'none',
    height: ({scrollHeight}) => `${scrollHeight}px`,
  },
  textHide: {
    maxHeight: ({rowsCount}) => `${rowsCount * 24}px`,
  },
  showMore: {
    fontSize: '13px',
    lineHeight: '22px',
    fontWeight: 500,
    color: theme.palette.secondary.main,
    cursor: 'pointer',
  },
}));

type Props = {
  children: string;
  rowsCount?: number;
  buttonText?: string;
  classes?: Partial<Record<'root' | 'text' | 'showMore', string>>;
};

export const TextWithShowMore = memo(({children, rowsCount = 2, buttonText, classes}: Props) => {
  const {t} = useTranslation();
  const [isShowMoreClicked, setIsShowMoreClicked] = useState(false);

  const ref = useRef<HTMLElement | null>(null);
  const styles = useStyles({
    rowsCount,
    clientHeight: ref.current?.clientHeight,
    scrollHeight: ref.current?.scrollHeight,
  });
  const isOverflow = useIsOverflow(ref);

  const onShowMoreClick = useCallback(() => setIsShowMoreClicked((value) => !value), []);
  return (
    <Box className={classes?.root}>
      <Typography
        variant="body1"
        ref={ref}
        className={cx(isShowMoreClicked ? styles.textShowed : styles.textHide, styles.text, classes?.text)}
      >
        {children}
      </Typography>
      {isOverflow && (
        <Typography className={cx(styles.showMore, classes?.showMore)} onClick={onShowMoreClick}>
          {buttonText ?? isShowMoreClicked ? t('Show less') : t('See more')}
        </Typography>
      )}
    </Box>
  );
});
