import {MutableRefObject, useLayoutEffect, useState} from 'react';

export const useIsOverflow = (ref: MutableRefObject<HTMLElement | null>, callback?: (v: boolean) => void) => {
  const [isOverflow, setIsOverflow] = useState(false);

  useLayoutEffect(() => {
    const {current} = ref;

    if (current) {
      const hasOverflow = current?.scrollHeight > current?.clientHeight;
      setIsOverflow(hasOverflow);
      if (callback) callback(hasOverflow);
    }
  }, [callback, ref]);

  return isOverflow;
};
