import {FullCreatorInterface} from '@cere/services-types/dist/types';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import {ChangeEvent, useCallback, useMemo, useState} from 'react';

import {useLocalization} from '../../../../hooks/use-locale.hook';
import {UsersNftCardInterface} from '../../../../types/nft';
import {CollapseBox} from '../../../primitives/CollapseBox';
import {ErrorMsg} from '../../../primitives/ErrorMsg';
import {ImageSquare} from '../../../primitives/ImageSquare';

const useStyles = makeStyles((theme) => ({
  fieldsRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: '8px',
    margin: '16px 0',
  },
  field: {
    flexGrow: 2,
    '& .MuiOutlinedInput-root': {
      borderRadius: '16px',
    },
  },
  nftBlock: {
    marginTop: '8px',
    background: '#E0E0E7',
    padding: '8px',
    borderRadius: '12px',
  },
  imageRoot: {
    padding: '0',
  },
  imageBox: {
    position: 'relative',
    height: 'auto',
    paddingBottom: '-100%',
  },
  nftImage: {
    width: '60px',
    height: '60px',
  },
  accordion: {
    backgroundColor: 'transparent',
    border: '1px solid #E0E0E7',
    borderRadius: '12px',
  },
  accordionDetails: {
    padding: 0,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  avatar: {
    width: '22px',
    height: '22px',
    marginRight: '3px',
    fontSize: '12px',
    backgroundColor: theme.palette.secondary.light,
  },
  submitButton: {
    marginTop: '16px',
  },
  table: {
    '& .MuiTableRow-head': {
      '& .MuiTableCell-head': {
        borderBottom: 'none',
      },
    },
    '& .MuiTableCell-head': {
      color: '#5F5C64',
      fontSize: '12px',
      lineHeight: '18px',
    },
    '& .MuiTableBody-root': {
      '& .MuiTableRow-root:last-child': {
        '& .MuiTableCell-root': {
          borderBottom: 'none',
        },
      },
    },
  },
}));

type Props = {
  amount: number;
  price: number;
  onSell: (price: number, quantity: number) => void;
  nft?: UsersNftCardInterface;
  creator?: FullCreatorInterface;
  royalty: number;
};

export const NftForm = ({nft, creator, amount, royalty, price, onSell}: Props) => {
  const {t} = useLocalization();
  const [sellingPrice, setSellingPrice] = useState<number | undefined>(price);
  const [isProcessing, setProcessing] = useState<boolean>(false);
  const styles = useStyles();

  const handlePriceChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const inputPrice = e.target.value;
    if (inputPrice === '') {
      setSellingPrice(undefined);
    } else {
      const nftPrice = Number(inputPrice);
      setSellingPrice(nftPrice);
    }
  }, []);

  const hasError = useMemo(() => {
    if (sellingPrice === undefined) {
      return true;
    } else {
      const nftPrice = Number(sellingPrice);
      return isNaN(nftPrice) || nftPrice < 0;
    }
  }, [sellingPrice]);

  const handleOnSellClick = useCallback(() => {
    setProcessing(true);
    onSell(sellingPrice!, amount);
  }, [onSell, amount, sellingPrice]);

  return (
    <>
      <Typography>{t("Decide how many collectibles you're willing to sell and at what price")}</Typography>
      <Box className={styles.nftBlock}>
        <div className="grid grid-cols-[60px_auto] gap-x-3 items-center">
          <ImageSquare
            image={nft?.image}
            title={nft?.title}
            classes={{root: styles.imageRoot, image: styles.nftImage, imageBox: styles.imageBox}}
          />
          <Box>
            <Typography variant="subtitle1">{nft?.title}</Typography>
            <div className="flex">
              <Typography variant="body2" className="!mr-4">
                Price: {sellingPrice || 0}$
              </Typography>
              <Typography variant="body2">Quantity: {amount}</Typography>
            </div>
          </Box>
        </div>
      </Box>
      <Box className={styles.fieldsRow}>
        <Box className={styles.field}>
          <TextField
            fullWidth
            variant="outlined"
            InputProps={{endAdornment: <InputAdornment position="start">USD</InputAdornment>}}
            inputProps={{type: 'number'}}
            label={t('Price')}
            value={sellingPrice}
            onChange={handlePriceChange}
            disabled={isProcessing}
          />
        </Box>
        <Box className={styles.field}>
          <TextField
            fullWidth
            variant="outlined"
            inputProps={{type: 'number'}}
            label={t('Quantity')}
            value={amount}
            disabled
          />
        </Box>
      </Box>
      {hasError && (
        <Box pb="16px">
          <ErrorMsg variant="error" text={`${t('Min price has to be more than')} 0`} />
        </Box>
      )}
      <CollapseBox
        summary={
          <div className="grid grid-cols-[24px_auto] gap-x-2 items-center">
            {!creator?.avatar ? (
              <Avatar className={styles.avatar} />
            ) : (
              <Avatar className={styles.avatar} src={creator.avatar.url} alt={creator.name} />
            )}
            <Typography>
              {t('{{royalty}}% royalties will be shared with {{name}}', {royalty, name: creator?.name})}
            </Typography>
          </div>
        }
        classes={{root: styles.accordion, details: styles.accordionDetails}}
      >
        <Table className={styles.table}>
          <TableBody>
            <TableRow>
              <TableCell>{creator?.name}</TableCell>
              <TableCell align="right">{royalty}%</TableCell>
              <TableCell align="right">0 USDC</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <b>Total</b>
              </TableCell>
              <TableCell align="right">{royalty}%</TableCell>
              <TableCell align="right">0 USDC</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CollapseBox>
      <Button
        fullWidth
        variant="contained"
        color="default"
        className={styles.submitButton}
        disabled={hasError || isProcessing}
        onClick={handleOnSellClick}
      >
        {isProcessing ? <CircularProgress size={30} /> : t('List For Sale')}
      </Button>
    </>
  );
};
