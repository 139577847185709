import {v4 as uuidv4} from 'uuid';

import {IDENTITY_API_URL} from '../../config/common';
import {post} from '../lib/request';
import {LocalStorageKeys} from '../types/local-storage-keys';
import {UtmData} from '../types/utm';
import {CereProvider} from './analytic-providers/cere-provider';
import {FacebookProvider} from './analytic-providers/facebook-provider';
import {GTMProvider} from './analytic-providers/gtm-provider';
import {ProviderInterface, User} from './analytic-providers/provider-interface';
import {SegmentProvider} from './analytic-providers/segment-provider';

export enum AnalyticEventsEnum {
  CHECKOUT_STARTED = 'CHECKOUT_STARTED',

  APP_LAUNCHED = 'APP_LAUNCHED',

  INTERSTITIAL_MAIN_BUY_ACCESS_CLICKED = 'INTERSTITIAL_MAIN_BUY_ACCESS_CLICKED',
  INTERSTITIAL_WHAT_IS_TICKET_CLICKED = 'INTERSTITIAL_WHAT_IS_TICKET_CLICKED',
  INTERSTITIAL_SECONDARY_ACCESS_CLICKED = 'INTERSTITIAL_SECONDARY_ACCESS_CLICKED',
  INTERSTITIAL_BUY_EMAIL_FOCUSED = 'INTERSTITIAL_BUY_EMAIL_FOCUSED',
  INTERSTITIAL_BUY_SEND_CLICKED = 'INTERSTITIAL_BUY_SEND_CLICKED',

  INTERSTITIAL_NFT_CARD_BUY_CLICKED = 'INTERSTITIAL_NFT_CARD_BUY_CLICKED',
  INTERSTITIAL_NFT_CARD_CLICKED = 'INTERSTITIAL_NFT_CARD_CLICKED',

  INTERSTITIAL_SUBSCRIBE_CLICKED = 'INTERSTITIAL_SUBSCRIBE_CLICKED',
  INTERSTITIAL_SUBSCRIBE_EMAIL_FOCUSED = 'INTERSTITIAL_SUBSCRIBE_EMAIL_FOCUSED',
  INTERSTITIAL_SUBSCRIBE_EMAIL_ON_EXIT_FOCUSED = 'INTERSTITIAL_SUBSCRIBE_EMAIL_ON_EXIT_FOCUSED',
  INTERSTITIAL_SUBSCRIBE_SUBMIT = 'INTERSTITIAL_SUBSCRIBE_SUBMIT',
  INTERSTITIAL_SUBSCRIBE_ON_EXIT_SUBMIT = 'INTERSTITIAL_SUBSCRIBE_ON_EXIT_SUBMIT',

  INTERSTITIAL_ACCESS_NFT_CLICKED = 'INTERSTITIAL_ACCESS_NFT_CLICKED',
  INTERSTITIAL_ACCESS_NFT_GOT_IT_CLICKED = 'INTERSTITIAL_ACCESS_NFT_GOT_IT_CLICKED',
  INTERSTITIAL_ACCESS_NFT_CLOSE_CLICKED = 'INTERSTITIAL_ACCESS_NFT_CLOSE_CLICKED',

  INTERSTITIAL_GO_TO_EVENT_CLICKED = 'INTERSTITIAL_GO_TO_EVENT_CLICKED',

  EXHIBIT_LANDED = 'EXHIBIT_LANDED',

  EXHIBIT_ENTER_BUTTON_CLICKED = 'EXHIBIT_ENTER_BUTTON_CLICKED',
  EXHIBIT_SHARE_CLICKED = 'EXHIBIT_SHARE_CLICKED',
  EXHIBIT_MAIN_NFT_SHARE_CLICKED = 'EXHIBIT_MAIN_NFT_SHARE_CLICKED',

  EXHIBIT_NFT_BID_CLICKED = 'EXHIBIT_NFT_BID_CLICKED',
  EXHIBIT_NFT_BUY_CLICKED = 'EXHIBIT_NFT_BUY_CLICKED',
  EXHIBIT_NFT_VIEW_CLICKED = 'EXHIBIT_NFT_VIEW_CLICKED',

  EXHIBIT_NFT_CARD_CLICKED = 'EXHIBIT_NFT_CARD_CLICKED',

  AUCTION_NFT_BID_CLICKED = 'AUCTION_NFT_BID_CLICKED',
  AUCTION_MORE_INFO_CLICKED = 'AUCTION_MORE_INFO_CLICKED',
  AUCTION_CARD_NAVIGATION_ARROW_CLICKED = 'AUCTION_CARD_NAVIGATION_ARROW_CLICKED',
  AUCTION_CARD_VISUAL_CLICKED = 'AUCTION_CARD_VISUAL_CLICKED',

  EXHIBIT_PLAYER_ABOUT_CLICKED = 'EXHIBIT_PLAYER_ABOUT_CLICKED',
  EXHIBIT_PLAYER_ABOUT_CLOSED = 'EXHIBIT_PLAYER_ABOUT_CLOSED',
  EXHIBIT_PLAYER_BROWSE_NFTS_CLICKED = 'EXHIBIT_PLAYER_BROWSE_NFTS_CLICKED',
  EXHIBIT_PLAYER_BROWSE_NFTS_CLOSED = 'EXHIBIT_PLAYER_BROWSE_NFTS_CLOSED',
  EXHIBIT_PLAYER_ABOUT_BROWSE_NFTS_CLICKED = 'EXHIBIT_PLAYER_ABOUT_BROWSE_NFTS_CLICKED',
  EXHIBIT_PLAYER_PAUSE_CLICKED = 'EXHIBIT_PLAYER_PAUSE_CLICKED',
  EXHIBIT_PLAYER_VOLUME_CHANGED = 'EXHIBIT_PLAYER_PAUSE_CLICKED',
  EXHIBIT_PLAYER_FULLSCREEN_CHANGED = 'EXHIBIT_PLAYER_PAUSE_CLICKED',

  MARKETPLACE_SELL = 'MARKETPLACE_SELL',
  MARKETPLACE_BUY = 'MARKETPLACE_BUY',
  HOMEPAGE_BANNER_NFT_CLICKED = 'HOMEPAGE_BANNER_NFT_CLICKED',
  HOMEPAGE_BANNER_EXHIBIT_CLICKED = 'HOMEPAGE_BANNER_EXHIBIT_CLICKED',
  FEATURED_NFT_LISTING_CLICKED = 'FEATURED_NFT_LISTING_CLICKED',
  NFT_COLLECTABLE_CLICKED = 'NFT_COLLECTABLE_CLICKED',

  AUCTION_NFT_CARD_BID_CLICKED = 'AUCTION_NFT_CARD_BID_CLICKED',
  AUCTION_NFT_CARD_BUY_CLICKED = 'AUCTION_NFT_CARD_BUY_CLICKED  ',
  AUCTION_NFT_CARD_CLICKED = 'AUCTION_NFT_CARD_CLICKED',
  AUCTION_TOGGLE_DETAILS_CLICKED = 'AUCTION_TOGGLE_DETAILS_CLICKED',

  EXHIBIT_WALLET_CLOSE_BUTTON_CLICKED = 'EXHIBIT_WALLET_CLOSE_BUTTON_CLICKED',
  AUCTION_WALLET_CLOSE_BUTTON_CLICKED = 'AUCTION_WALLET_CLOSE_BUTTON_CLICKED',
  LIMITED_WALLET_CLOSE_BUTTON_CLICKED = 'LIMITED_WALLET_CLOSE_BUTTON_CLICKED',

  WALLET_TOP_UP_CLICKED = 'WALLET_TOP_UP_CLICKED',
  CONNECT_WALLET_CLICKED = 'CONNECT_WALLET_CLICKED',
  TORUS_WALLET_CONNECT_CLICKED = 'TORUS_WALLET_CONNECT_CLICKED',
  METAMASK_WALLET_CONNECT_CLICKED = 'METAMASK_WALLET_CONNECT_CLICKED',
  WALLETCONNECT_WALLET_CONNECT_CLICKED = 'WALLETCONNECT_WALLET_CONNECT_CLICKED',
  WALLET_CONNECTED = 'WALLET_CONNECTED',
  WALLET_INPUT_FOCUSED = 'WALLET_INPUT_FOCUSED',
  WALLET_PLACE_BID_CLICKED = 'WALLET_PLACE_BID_CLICKED',
  TORUS_WALLET_COMPLETED = 'TORUS_WALLET_COMPLETED',
  METAMASK_WALLET_COMPLETED = 'METAMASK_WALLET_COMPLETED',
  WALLETCONNECT_WALLET_COMPLETED = 'WALLETCONNECT_WALLET_COMPLETED',

  SIGN_IN_HEADER_CLICK = 'SIGN_IN_HEADER_CLICK',
  SIGN_IN_BACK_CLICKED = 'SIGN_IN_BACK_CLICKED',
  SIGN_IN_LOGO_CLICKED = 'SIGN_IN_LOGO_CLICKED',
  SIGN_IN_INPUT_FOCUSED = 'SIGN_IN_INPUT_FOCUSED',
  SIGN_IN_SUBMIT_BUTTON_CLICKED = 'SIGN_IN_SUBMIT_BUTTON_CLICKED',
  SIGN_IN_SOCIAL_AUTH_CLICKED = 'SIGN_IN_SOCIAL_AUTH_CLICKED',
  SIGN_IN_TOGGLE_AUTH_CLICKED = 'SIGN_IN_TOGGLE_AUTH_CLICKED',
  SIGN_IN_OTP_VALUE_CHANGED = 'SIGN_IN_OTP_VALUE_CHANGED',
  SIGN_IN_OTP_RESEND_CLICKED = 'SIGN_IN_OTP_RESEND_CLICKED',
  SIGN_IN_OTP_SUBMITTED = 'SIGN_IN_OTP_SUBMITTED',
  SIGN_UP_INTERSTITIAL_INPUT_FOCUSED = 'SIGN_UP_INTERSTITIAL_INPUT_FOCUSED',
  SIGN_IN_INTERSTITIAL_INPUT_FOCUSED = 'SIGN_IN_INTERSTITIAL_INPUT_FOCUSED',
  SIGN_UP_INTERSTITIAL_SUBMIT_BUTTON_CLICKED = 'SIGN_UP_INTERSTITIAL_SUBMIT_BUTTON_CLICKED',
  SIGN_IN_INTERSTITIAL_SUBMIT_BUTTON_CLICKED = 'SIGN_IN_INTERSTITIAL_SUBMIT_BUTTON_CLICKED',
  SIGN_UP_INPUT_FOCUSED = 'SIGN_UP_INPUT_FOCUSED',
  SIGN_UP_TOS_TOGGLED = 'SIGN_UP_TOS_TOGGLED',
  SIGN_UP_NEWSLETTER_TOGGLED = 'SIGN_UP_NEWSLETTER_TOGGLED',
  SIGN_UP_SUBMIT_BUTTON_CLICKED = 'SIGN_UP_SUBMIT_BUTTON_CLICKED',

  USER_AUTHENTICATED = 'USER_AUTHENTICATED',
  USER_AUTHENTICATED_WEB = 'USER_AUTHENTICATED_WEB',
  USER_AUTHENTICATED_RN = 'USER_AUTHENTICATED_RN',

  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  SUBSCRIBE_EMAIL = 'SUBSCRIBE_EMAIL',
  SUBSCRIBE_EMAIL_ON_EXIT = 'SUBSCRIBE_EMAIL_ON_EXIT',

  // Tor.us wallet integration events
  TORUS_WALLET_FINISH_CONFIGURATION_CLICK = 'TORUS_WALLET_FINISH_CONFIGURATION_CLICK',
  TORUS_WALLET_CREATE_WALLET_CLICK = 'TORUS_WALLET_CREATE_WALLET_CLICK',
  TORUS_WALLET_FUND_WALLET_CLICK = 'TORUS_WALLET_FUND_WALLET_CLICK',

  //Concert page
  COLLECTIBLE_PURCHASE = 'COLLECTIBLE_PURCHASE',
  SIGN_IN_BUY_COLLECTIBLE = 'SIGN_IN_BUY_COLLECTIBLE',
  STAY_UPDATED_SUBSCRIPTION = 'STAY_UPDATED_SUBSCRIPTION',
  EMAIL_SUBMITTED = 'EMAIL_SUBMITTED',
}

export class AnalyticService {
  private readonly providers: ProviderInterface[];
  private readonly gtmProvider: ProviderInterface;

  constructor() {
    let sessionId = window.localStorage.getItem(LocalStorageKeys.SESSION_ID);
    if (!sessionId) {
      sessionId = uuidv4();
      window.localStorage.setItem(LocalStorageKeys.SESSION_ID, sessionId);
    }
    this.providers = [new CereProvider(sessionId), new SegmentProvider(sessionId), new FacebookProvider()];
    this.gtmProvider = new GTMProvider();
    this.init();
  }

  identify(user: User, metadata?: Record<string, string | number>) {
    this.providers.forEach((provider) => provider.identify(user, metadata));
  }

  init() {
    this.providers.forEach((provider) => provider.init());
  }

  track(event?: string, payload: Record<string, string | number | boolean | null> = {}) {
    if (event) {
      this.providers.forEach((provider) => provider.track(event, payload));
    }
  }

  gtmTrack(event?: string, payload: Record<string, string | number | boolean | null> = {}) {
    if (event) {
      this.gtmProvider.track(event, payload);
    }
  }
}

export const saveUtmData = async (jwtToken: string, utmData: UtmData): Promise<void> => {
  try {
    await post(
      `/analytics/save-utm-data`,
      {...utmData},
      {
        base: IDENTITY_API_URL(),
        headers: {Authorization: `Bearer ${jwtToken}`},
      },
    );
  } catch (error) {
    console.error('Failed to send utm data.', error.message);
  }
};

const analyticService = new AnalyticService();
export default analyticService;
