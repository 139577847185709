import {Box, makeStyles, Typography} from '@material-ui/core';
import clsx from 'clsx';

import colors from '../../../../styles/colors';
import {ReactComponent as CollectedIcon} from '../../../assets/svg/done.svg';

const useStyles = makeStyles(() => ({
  badgeBox: {
    display: 'inline-flex',
    alignItems: 'end',
    background: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '4px',
    width: 'fit-content',
    padding: '4px 8px',
    '& > svg': {
      marginRight: '4px',
    },
  },
  badgeText: {
    color: colors.white,
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '14.2px',
  },
}));
export const Badge = ({text, classes}: {text: string; classes?: Partial<Record<'badgeText' | 'badgeBox', string>>}) => {
  const styles = useStyles();
  return (
    <Box className={clsx(styles.badgeBox, classes?.badgeBox)}>
      <CollectedIcon className="self-center" />
      <Typography className={clsx(styles.badgeText, classes?.badgeText)}>{text}</Typography>
    </Box>
  );
};
