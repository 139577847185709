import {AxiosInstance} from 'axios';

import {getPopupPaymentUrl} from './helpers';
import {IPaymentApi} from './IPaymentApi';
import {PaymentUrlFetchError} from './PaymentUrlFetchError';
import {PaymentMode} from './types';

export class PaymentRESTApi implements IPaymentApi {
  constructor(private readonly client: AxiosInstance) {}

  async getFiatPaymentUrl(
    buyerEmail: string,
    nftId: string,
    orderId: string,
    collectionAddress: string,
    quantity: number,
    eventId: number,
    locale: string,
    paymentMode: PaymentMode,
    nftIdIncremental: string,
    buyerEthAddress?: string,
  ): Promise<string> {
    const {successUrl, cancelUrl} = getPopupPaymentUrl(
      locale,
      paymentMode,
      nftIdIncremental,
      orderId,
      buyerEmail,
      quantity.toString(),
    );
    const {data} = await this.client.post('/buy-nft-for-fiat', {
      buyerEmail,
      nftId,
      quantity,
      eventId: Number(eventId),
      successUrl,
      cancelUrl,
      currency: 'USD',
      locale,
      collectionId: collectionAddress,
      orderId: Number(orderId),
      buyerEthAddress: buyerEthAddress || undefined,
    });
    if (!data?.paymentUrl || typeof data.paymentUrl !== 'string') {
      throw new PaymentUrlFetchError();
    }
    return data.paymentUrl;
  }
}
