import {createAppConfigApi} from './app-config';
import {createAuctionsApi} from './auctions';
import {createCollectorApi} from './collector/CollectorApi';
import {createCreatorApi} from './creator/CreatorBFFApi';
import {createCreatorsApi} from './creators/CreatorsBFFApi';
import {createEventsApi} from './events';
import {createExhibitApi} from './exhibit/ExhibitApi';
import {createExhibitsApi} from './exhibits/ExhibitsApi';
import {apolloClient} from './graphqlClient';
import {createHomePageApi} from './home-page';
import {createNftsApi} from './nfts/NftsBFFApi';
import {createNotificationsApi} from './notifications/notifications-api';
import {notificationsRestClient} from './notificationsRestClient';
import {createOrdersApi} from './orders/OrdersBFFApi';
import {createOverlayApi} from './overlay-nfts/OverlayApi';
import {restClient} from './restClient';
import {createSubscribeApi} from './subscribe/SubscribeApi';
import {createTransfersApi} from './transfers/TransfersBFFApi';
import {createUserNftsApi} from './user-nfts-page/UserNftsBFFApi';

export const creatorsApi = createCreatorsApi(restClient);
export const creatorApi = createCreatorApi(restClient);
export const eventsApi = createEventsApi(restClient);
export const nftsApi = createNftsApi(restClient);
export const auctionsApi = createAuctionsApi(apolloClient);
export const appConfigApi = createAppConfigApi(apolloClient);
export const homePageApi = createHomePageApi(restClient);
export const exhibitsApi = createExhibitsApi(restClient, apolloClient);
export const exhibitApi = createExhibitApi(restClient);
export const subscribeApi = createSubscribeApi(restClient);
export const overlayApi = createOverlayApi(restClient);
export const ordersApi = createOrdersApi(restClient);
export const userNftsApi = createUserNftsApi(apolloClient);
export const collectorsApi = createCollectorApi(restClient);
export const notificationsApi = createNotificationsApi(notificationsRestClient, apolloClient);
export const transfersApi = createTransfersApi(restClient);
