export enum MaticTestnetIdEnum {
  MaticTestnetNetworkId = 80_001,
  MaticMainnetNetworkId = 137,
}

export const maticNetworkNames = {
  [MaticTestnetIdEnum.MaticTestnetNetworkId]: 'Mumbai Testnet',
  [MaticTestnetIdEnum.MaticMainnetNetworkId]: 'Matic Mainnet',
};

export const maticNetworkUrls = {
  [MaticTestnetIdEnum.MaticTestnetNetworkId]: 'https://mumbai.polygonscan.com/',
  [MaticTestnetIdEnum.MaticMainnetNetworkId]: 'https://polygonscan.com/',
};
