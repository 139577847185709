import {cx} from '@linaria/core';
import {Box, Theme, Typography, useMediaQuery} from '@material-ui/core';
import {ReactNode, Ref, useCallback, useState} from 'react';

import {REACT_APP_SHOW_COLLECTION_PROGRESS} from '../../../../../config/common';
import {ReactComponent as LockClosedIcon} from '../../../../assets/svg/lock-closed.svg';
import {ConnectedEventUnlockProgress} from '../../../../components/connected/EventUnlockProgress';
import {TextWithShowMore} from '../../../../components/primitives/TextWithShowMore';
import {ContentSlider} from '../../../../components/shared/ContentSlider/content-slider';
import {EventBanner} from '../../../../components/shared/EventBanner/EventBanner';
import {CmsExhibit, EventTimelineStatus, SliderTypeEnum} from '../../../../types/exhibit';
import {EventActionButton} from '../EventActionButton';
import {useStyles} from './styles';

export type Props = {
  isEventLocked: boolean;
  badge: NonNullable<ReactNode>;
  event: CmsExhibit;
  eventNftsCount: number;
  nftsEventBlockRef: Ref<HTMLDivElement> | null;
  bannerImage?: string;
  eventTimelineStatus: EventTimelineStatus;
  userWalletAddress: string | null;
};
export const EventTopSection = ({
  isEventLocked,
  badge,
  event,
  bannerImage,
  eventTimelineStatus,
  userWalletAddress,
  eventNftsCount,
  nftsEventBlockRef,
}: Props) => {
  const [sliderView, setSliderView] = useState<'preview' | 'full'>('full');
  const isMobile = useMediaQuery<Theme>((theme) => `${theme.breakpoints.down('md')}`);

  const {title, description, eventType, eventHiddenLocation, streamUrl} = event;
  const styles = useStyles({bannerImage});

  const handleChangeEventView = useCallback((view: 'preview' | 'full') => {
    setSliderView(view);
  }, []);

  return (
    <>
      <div className="overflow-hidden w-full flex flex-wrap sm:bg-white p-0 rounded-tl-[12px]">
        <div className="w-full sm:w-1/2 relative m-0 p-0">
          {badge}
          {!isMobile && (
            <ContentSlider
              userWalletAddress={userWalletAddress}
              slides={[
                {
                  preview: event?.image?.url ?? null,
                  previewFormats: event.image.formats,
                  background: null,
                  playerAsset: null,
                  playerPreviewUrl: null,
                  contentType: SliderTypeEnum.IMAGE,
                  hasAccess: true,
                  name: title,
                },
              ]}
              slidePreviewType="internal"
            />
          )}
        </div>
        <div className="w-full sm:w-1/2 m-0 p-0">
          <Box className={styles.playerBox}>
            <Box className={cx(styles.displayOnMobile, 'px-4 sm:px-0')}>
              <Box className="flex p-3 flex-row w-full">
                {isEventLocked && <LockClosedIcon className="h-[28px] w-[28px]" />}
                <Typography variant="h3">{title}</Typography>
              </Box>
              <EventBanner event={event} />
            </Box>
            <Box className="px-4 py-0 sm:px-0 w-full">
              <Box className="flex flex-col justify-items-stretch w-full bg-white py-4 sm:pt-2">
                <Box className="w-full sm:px-3">
                  {REACT_APP_SHOW_COLLECTION_PROGRESS && (
                    <Box className="w-full hidden sm:flex border-2 border-gray-200 p-4 rounded-[12px] bg-white">
                      <ConnectedEventUnlockProgress eventSlug={event.slug} />
                    </Box>
                  )}
                </Box>
                <Box className="p-3">
                  <TextWithShowMore rowsCount={5}>{description}</TextWithShowMore>
                </Box>
                <Box className="px-3 sm:pt-3">
                  <EventActionButton
                    startsAt={event.startsAt!}
                    isEventLocked={isEventLocked}
                    eventType={eventType}
                    eventHiddenLocation={eventHiddenLocation}
                    streamUrl={streamUrl}
                    eventTimelineStatus={eventTimelineStatus}
                    sliderView={sliderView}
                    eventNftsCount={eventNftsCount}
                    onChangeView={handleChangeEventView}
                    nftsEventBlockRef={nftsEventBlockRef}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};
