export const ROUTES = {
  HOME: '/:locale/home',
  MARKETPLACE: '/:locale/marketplace',
  NFT_PAGE: '/:locale/home/collectible/:nftId',
  NFT_MARKETPLACE: '/:locale/home/collectible/:nftId/marketplace',
  EVENTS: '/:locale/events',
  EVENT: '/:locale/home/event/:exhibitSlug',
  CREATORS: '/:locale/creators',
  CREATOR: '/:locale/home/creator/:artistId',
  WISHLIST: '/:locale/wishlist',
  NOTIFICATIONS: '/:locale/notifications',
  ACTIVITY: '/:locale/activity',
  SIGN_IN: '/:locale/home/auth/signin',
  SIGN_UP: '/:locale/home/auth/signup',
  USER_NFTS: '/:locale/home/user/nfts',
  USER_WALLET: '/:locale/home/user/wallet',
  WALLET_TRANSFER: '/:locale/home/user/wallet/transfer',
  VIDEOS: '/:locale/videos',
  COLLECTOR_PROFILE: '/:locale/home/user/profile/:walletPublicKey',
  MY_PROFILE: '/:locale/home/user/profile',
};
